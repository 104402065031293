import React from "react"
import SEO from "../components/seo"
import ImageMock from "../components/image-mock"



const IndexMock = () => (
  <>
    <SEO title="Empowering all in conversations with visuals" />
      <ImageMock />

  </>
)

export default IndexMock
